<template>
    <div>
        <bannerFr></bannerFr>
        <div class="cont-search">
            <div class="search-l">
                <input v-model="searchText" placeholder="keywords" type="text">
            </div>
            <div class="search-r" @click="searchGame">
                GO
            </div>
        </div>
        <div class="cont-class">
            <div class="pointer" v-for="item in topClass" :key="item.name" @click="getMenuGame(item.name)">
                <img :src="item.url" alt="">
                <p>{{item.name}}</p>
            </div>
        </div>
        <div class="cont-st">
            <div class="cont-st-h">
                <p class="noselect">{{firstList.title}} GAMES</p>
                <div class="pointer" @click="goselect(firstList.title)">more</div>
            </div>
            <div class="cont-st-card pointer" v-for="item in firstList.games" :key="item.id" @click="goDetails(item)">
                <img :src="item.logo" alt="">
                <div>
                    <van-rate
                    v-model="item.rating"
                    readonly
                    :size="10"
                    color="#FFE141"
                    void-icon="star"
                    void-color="#eee"
                    />
                    <span>{{item.rating}}</span>
                </div>
            </div>
        </div>
        <bannerNd></bannerNd>
        <div class="cont-st cont-list">
            <div class="cont-st-h">
                <p class="noselect">{{gameList.title}} GAMES</p>
            </div>
            <div class="cont-list-card pointer" v-for="items in gameList.games" :key="items.id" @click="goDetails(items)">
                <img :src="items.logo" alt="">
                <p class="no-warp">{{items.title}}</p>
            </div>
            <span v-for="item in spanNum" :key="item+'s'"></span>
        </div>
        <div class="game-blurb noselect">
            {{ blurbUrl }} is a global leader in online games, offering a diverse range of free-to-play titles across various genres including action, sports, strategy, and puzzle. With a rich history spanning over two decades, Miniclip has established itself as a trusted and popular destination for gamers worldwide. The platform boasts a user-friendly interface and seamless gameplay, ensuring that players can enjoy their favorite games without any hassle. Miniclip's commitment to quality and innovation has led to the development of numerous hit games, such as 8 Ball Pool, Agar.io, and Soccer Stars. The site also features a vibrant community where players can connect, compete, and share their experiences.
        </div>
        <anchorFr></anchorFr>
        <interstitialFr :id="id" v-if="isAD"></interstitialFr>

    </div>
</template>

<script>
import topClass from '@/utils/topClass'
import { getRandomNumbers, isMobile } from "@/utils/api"
import eventBus from '@/utils/eventBus'
import { getGame } from '@/api/games'
import bannerFr from "@/components/adContent/banner1.vue";
import bannerNd from "@/components/adContent/banner2.vue";
import anchorFr from "@/components/adContent/anchor1.vue";
import interstitialFr from "@/components/adContent/interstitial1.vue";

export default {
        components: {
            bannerFr,
            bannerNd,
            anchorFr,
            interstitialFr
        },
        data() {
            return {
                topClass,
                gameList:[],
                value:4,
                allList:[],
                gameListC:[],
                firstList:{},
                spanNum:0,
                searchText:'',
                blurbUrl: process.env.VUE_APP_BASE_GAME,
                isAD:false,
                id:''
            }
        },
        mounted() {
            this.getList();
            if(isMobile()){
                this.spanNum = 3-2
            }else{
                this.spanNum = 11-2
            }
            let menukey = this.$route.params.menuKey;
            if(menukey){
                this.getMenuGame(menukey)
            }else{
                eventBus.$on('selectMenu',key=>{                    
                    this.getMenuGame(key)
                });
            }
        },
        methods: {
            goDetails(item){
                this.isAD = true;
                this.id = item.id
                // this.$router.push({
                //     path:'/details',
                //     query:{
                //         id:item.id
                //     }
                // })
            },
            async getList(){
                let gameList = await getGame();
                this.gameList = {
                    title: 'All',
                    games:gameList
                };
                let firstList = await getGame({count:9})
                this.firstList = {
                    title:firstList[0].key_name,
                    games:firstList
                } 
            },
            getRandomGames(str,end,len){
                let gamelist = getRandomNumbers(str,end,len);

                gamelist.forEach((item,i)=>{
                    gamelist.splice(i,1,this.allList[item])
                })

                return gamelist;
            },
            async getMenuGame(name){
                let key = name;
                this.$router.push({
                    path:'/selectGame',
                    query:{
                        id:'more',
                        key
                    }
                })
            },
            goselect(item){
                this.$router.push({
                    path: 'selectGame',
                    query:{
                        id: 'more',
                        key: item
                    }
                })
            },
            searchGame(){
                this.$router.push({
                    path: 'selectGame',
                    query:{
                        id: 'search',
                        key: this.searchText
                    }
                })
            }
        },
    }
</script>

<style lang="less" scoped>
@media (max-width: 768px) {
  .cont-search{
        justify-content: space-between;
    }
    .cont-class{
        justify-content: space-between;
    }
}
@media (min-width: 768px) {
    .cont-search{
        justify-content: center;
        .search-l{
            margin-right: 10px;
        }
    }
    .cont-class{
        justify-content: center;
        div{
            margin: 0 10px;
        }
    }
}
.cont-search{
    display: flex;
    padding: 0 20px;
    margin: 10px 0 11px;
    height: 40px;
    align-items: center;
    text-align: center;
    line-height: 40px;
    .search-l{
        width: 281px;
        height: 40px; 
        background: #FFFAF9;
        border-radius: 10px;
        overflow: hidden;
        input{
            &::placeholder {
                color: #FFBDBA;
            }
            margin-left: 13px;
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #994B46;
            line-height: 22px;
            text-align: left;
            font-style: normal;
        }
    }
    .search-r{
        width: 49px;
        height: 40px;
        background: linear-gradient( 180deg, #FFEF53 0%, #FF9100 100%);
        box-shadow: 0px 2px 4px 0px #D6534D;
        border-radius: 10px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #FFFAF9;
        text-align: center;
        font-style: normal;
    }
}
.noselect{
    -moz-user-select:none;/*火狐*/

    -webkit-user-select:none;/*webkit浏览器*/

    -ms-user-select:none;/*IE10*/

    -khtml-user-select:none;/*早期浏览器*/

    user-select:none;
}
.pointer{
    cursor: pointer;
}
.cont-class{
    display: flex;
    margin-bottom: 16px;
    padding: 0 20px;
    div{
        @media (min-width: 768px){
            width: 300px;
        }
        @media (max-width: 768px){
            width: 80px;
        }
        height: 80px;
        background: linear-gradient( 180deg, #FFFFFF 0%, #FFECEB 100%);
        box-shadow: 0px 2px 8px 0px rgba(214,83,77,0.72);
        border-radius: 10px;
        border: 1px solid #FFFFFF;
        img{
            width: 54px;
            height: 54px;
            display: block;
            margin: 0 auto;
        }
        p{
            text-align: center;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #994B46;
            line-height: 20px;
            font-style: normal;
        }
    }
}
.cont-st{
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .cont-st-h{
        width: 100%;
        height: 40px;
        // background: #504BA9;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 18px;
            color: #521E1B;
            line-height: 25px;
            text-align: left;
            font-style: normal;
            // margin-left: 12px;
        }
        div{
            margin-right: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 22px;
            text-align: left;
            font-style: normal;
            text-transform: uppercase;
        }
    }
    .cont-st-card{
        // margin: 12px 0;
        // padding-top: 6px;
        box-sizing: border-box;
        width: 101px;
        // height: 152px;
        // background: #FFFFFF;
        border-radius: 11px;
        text-align: center;
        img{
            width: 101px;
            height: 101px;
            border-radius: 15px;
        }
        p{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #000000;
            line-height: 20px;
            font-style: normal;
        }
        div{
            display: flex;
            justify-content: space-between;
            align-items: center;
            span{
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 20px;
                text-align: right;
                font-style: normal;
            }
        }
    }
}
.cont-ed{
    .cont-ed-card{
        width: 162px;
        height: 86px;
        padding: 5px;
        margin: 8px 0 0 0;
        box-sizing: border-box;
        background: #FFFFFF;
        border-radius: 10px;
        display: flex;
        align-content: center;
        justify-content: space-between;
        .ed-card-l{
            width: 76px;
            height: 76px;
            border-radius: 10px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .ed-card-r{
            width: 70px;
            overflow: hidden;
            p{
                margin-bottom: 14px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #000000;
                line-height: 20px;
                text-align: left;
                font-style: normal;
            }
            .play{
                margin: 0 auto;
                width: 63px;
                height: 21px;
                text-align: center;
                line-height: 21px;
                background: #504BA9;
                border-radius: 12px;

                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 9px;
                color: #FFFFFF;
                font-style: normal;
                text-transform: uppercase;
            }
        }
    }
}
.cont-list{
    // &::after{
    //     content: '   ';
    //     flex: auto;
    //     display: block;
    //     width: 100px;
    // }
    .cont-list-card{
        width: 100px;
        margin: 16px 0 0 0;
        img{
            width: 100px;
            height: 100px;
            border-radius: 15px;
            border: 2px solid #FFFFFF;
        }
        p{
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            font-size: 14px;
            color: #FFF;
            line-height: 20px;
            text-align: left;
            font-style: normal;
        }
    }
    span{
        width: 100px;
    }
}
.no-warp{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.game-blurb{
    border-radius: 10px;
    padding: 8px 12px;
    box-sizing: border-box;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #FFF;
    line-height: 22px;
    text-align: left;
    font-style: normal;
}
</style>